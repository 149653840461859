import React, { FC } from 'react'
import { Popover as ANTDPopover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import { PoboverStyle } from '../../../style/styled/antd/Popover.style'

export const Popover: FC<PopoverProps> = (props) => (
  <>
    <style jsx global>
      {PoboverStyle}
    </style>
    <ANTDPopover {...props} />
  </>
)
